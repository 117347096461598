(function($,w){$(function(){"use strict";

	// Lazy Load
	// var LazyLoad = function( $opt ) { this.init( $opt ); }
	// $.extend( LazyLoad.prototype,
	// {
	// 	init : function( $opt )
	// 	{
	// 		this.contrl = new ScrollMagic.Controller();
	// 		this.prepare( $opt );
	// 		this.lazy( $opt );
	// 	}, // end init

	// 	prepare : function( $opt )
	// 	{
	// 		// images
	// 		for ( var key in $opt.obj )
	// 		{
	// 			if ( $opt.obj[key].child )
	// 				$( '#' + key ).children( $opt.obj[key].child ).attr('data-src', $opt.imFold + $opt.obj[key].url );
	// 			else
	// 				$( '#' + key ).attr('data-src', $opt.imFold + $opt.obj[key].url );
	// 		}

	// 		// svgs
	// 		var $i = 0, $l = $opt.svgs.length;

	// 		for ( $i; $i < $l; $i++ )
	// 		{
	// 			$( '#' + $opt.svgs[$i] + '-svg' ).attr({ 'data-loader': 'getSVGFrag', 'data-id': $opt.svgs[$i] });
	// 		}
	// 	}, // end prepare

	// 	lazy : function( $opt )
	// 	{
	// 		var $this = this;

	// 		$.Lazy( 'getSVGFrag', function( el, resp )
	// 		{
	// 			var id = el.data( 'id' );
	// 	        el.load( $opt.imFold + $opt.svFile + ' #' + id );
	// 	        el.removeAttr( 'data-id' );
	// 	        resp( true );
	// 	    });

	// 		$( 'div.lazy, div.lazy img' ).Lazy(
	// 		{
	// 			visibleOnly: true,
	// 			defaultImage: null,
	// 			effect: 'fadeIn',
	// 			effectTime: 0,
	// 			afterLoad: function( el )
	// 			{
	// 				var id = el.prop( 'id' );

	// 				if ( /svg/.test( id ) )
	// 				{
	// 					var int = setInterval( function()
	// 					{
	// 						if ( el.find( 'svg' ).length > 0 )
	// 						{
	// 							clearInterval( int );

	// 							var items = el.find( 'path,circle,ellipse,rect,polygon' ),
	// 								tl    = new TimelineMax();

	// 				            TweenMax.set( el.children( 'svg' ), { visibility: 'visible', } );
	// 				            TweenMax.set( items, { strokeWidth: '0.2%', } );

	// 				            tl
	// 				                .from( items, 4, { drawSVG: 0, ease:Power1.easeInOut } )
	// 				                .add( 'end', 3.8 )
	// 				                .from( items, 1, { fill: 'none', ease:Power1.easeInOut }, 'end' )
	// 				                .to( items, 1, { strokeWidth: '0', ease:Power1.easeInOut }, 'end' );

	// 							new $.ScrollMagic.Scene({
	// 								triggerElement: el,
	// 								triggerHook: .9,
	// 								duration: '55%',
	// 							})
	// 							.setTween( tl )
	// 							.addTo( $this.contrl );
	// 						}
	// 					}, 10 );
	// 				}
 //        		},
	// 		});
	// 	}, // end lazy
	// }); // end LazyLoad

	// new LazyLoad(
	// {
	// 	imFold: 'https://cdn.indikator-design.com/wp-content/uploads',
	// 	svFile: '/svg/home3.svg',
	// 	svgs:   [ 'development', 'design', 'multisite', 'optimization', 'security', 'transfer', 'ecommerce', 'seo', 'seoBlogs', 'api' ],
	// 	obj:
	// 	{
	// 		webDev:   { url: '/wordpress-developer-website-professional-indikator-design.jpg' },
	// 		photo:    { url: '/photographer-video-editor-3d-animator-indikator-design.jpg' },
	// 		me:       { child: 'img', url: '/wordpress-developer-bruno-bouyajdad-indikator-design.png' },
	// 		wife:     { child: 'img', url: '/my-wonderful-wife-bruno-bouyajdad-indikator-design.png' },
	// 		physics:  { url: '/galaxy-and-atom-human-eye-indikator-design.jpg' },
	// 		why:      { url: '/why-work-as-wordpress-developer-indikator-design.jpg' },
	// 		passion:  { url: '/wordpress-development-as-passion-indikator-design.jpg' },
	// 		relation: { url: '/relationship-with-customers-as-wordpress-developer-indikator-design.jpg' },
	// 		skills:   { url: '/program-code-on-a-screen-indikator-design.jpg' },
	// 		prodBlog: { url: '/divi-blog-indikator-design.jpg' },
	// 		prodWoo:  { url: '/divi-woocommerce-indikator-design.jpg' },
	// 	}
	// });

	// // Page Animations
	// var PageAnimations = function( $opt ) { this.init( $opt ); }

	// $.extend( PageAnimations.prototype,
	// {
	// 	init : function( $opt )
	// 	{
	// 		this.vars();
	// 		this.events();
	// 		this.load();
	// 	}, // end init

	// 	vars : function()
	// 	{
	// 		this.contrl = new ScrollMagic.Controller();
	// 		this.header = $( '#header-bg' );
	// 		this.text01 = $( '#inter1' );
	// 		this.text02 = $( '#inter2' );
	// 		this.text03 = $( '#inter3' );
	// 		this.text04 = $( '#inter4' );
	// 		this.text05 = $( '#inter5' );
	// 	}, // end vars

	// 	load : function()
	// 	{
	// 		this.tagReplace();
	// 		this.headText();
	// 		this.headAnim();
	// 		this.fadeIn();
	// 		this.bioClose();
	// 		this.skills();
	// 		this.textAnim( this.text01.find( 'blockquote' ), this.text01, .9, '60%' );
	// 		this.textAnim( this.text02.find( 'blockquote' ), this.text02, .9, '60%' );
	// 		this.textAnim( this.text03.find( 'blockquote' ), this.text03, .9, '10%' );
	// 		this.textAnim( this.text04.find( 'blockquote' ), this.text04, .9, '60%' );
	// 		this.textAnim( this.text05.find( 'blockquote' ), this.text05, .9, '60%' );
	// 	}, // end load

	// 	tagReplace : function()
	// 	{
	// 		var targ = $( 'h2.et_bloom_success_message' );

	// 		targ.replaceWith( '<h4 class="et_bloom_success_message">' + targ.html() +'</h4>');
	// 	},

	// 	headText : function()
	// 	{
	// 		var $this = this,
	// 			headr = $( '#header-anim' ),
	// 			intro = headr.children( 'p.first' ),
	// 			cursr = $( '#cursor' ),
	// 			iText = intro.text(),
	// 			func  = headr.children( 'div.sec' ),
	// 			spans = func.find( 'span' ).map( function()
	// 			{
	// 				return { sel : $(this), txt : $(this).text() };
	// 			}),
	// 			tl    = new TimelineMax();

	// 		headr.find( '.t-clear' ).empty();

	// 		setTimeout( function()
	// 		{
	// 			TweenMax.set( [ intro, cursr, func ], { display : 'block', });
	// 		}, 500 );

	// 		var execute = function( $sel, $txt, $del, $rm, $len, $div, $dif )
	// 		{
	// 			var len = $len || 100,
	// 				div = $div || 30,
	// 				del = $del || 0,
	// 				dif = $dif || 0;

	// 			tl.to( $sel, $len / div,
	// 			{
	// 				text  : $txt,
	// 				ease  : Linear.easeNone,
	// 				delay : del,
	// 				onComplete : function()
	// 				{
	// 					if ( 'del' == $rm )
	// 						setTimeout( function(){ deExecute( len ); }, 1200 );
	// 					if ( 'scroll' == $rm )
	// 						setTimeout( function(){ $( '.scroll-down').css( 'display', 'block' )}, 2000 );
	// 				}
	// 		    }, '+=' + dif );
	// 		}, // execute

	// 		deExecute = function( $len )
	// 		{
	// 			var del = intro.length / 10,
	// 				txt = new SplitText( intro, { type : 'chars' } ),
	// 				int = setInterval( function()
	// 				{
	// 					var divs = intro.find( 'div' );

	// 					if ( 0 === divs )
	// 						setTimeout( function(){ return clearInterval( int ); }, $len );
	// 					divs.last().remove();
	// 				}, $len );
	// 		} // deExecute

	// 		// execute( intro, 'Congratulations!', 2.5, 'del', 25, 25, 0.5 );
	// 		execute( intro, iText, 1.5, 'del', 50, 25, 0.5 );

	// 		spans.each( function( i, el )
	// 		{
	// 			var del = 0 === i ? 2.5 : 0;

	// 			execute( $(this)[0].sel, $(this)[0].txt, del, 'scroll', 10, 25, 0 );
	// 		});
	// 	}, // end headText

	// 	headAnim : function()
	// 	{
	// 		var tr = this.header,
	// 			hd = tr.children( 'section' ),
	// 			tl = new TimelineMax();

	// 		tl
	// 			.to( hd, 1, { y: '40%', autoAlpha: 0, ease: Power0.easeNone, }, 0 )
	// 		    .to( hd.children( '.et_pb_fullwidth_header_container' ), .8, { y: '-30%', autoAlpha: 0, ease: Power0.easeNone, }, 0 )
	// 		    .to( hd.children( '.et_pb_fullwidth_header_scroll' ), .8, { bottom: '30%', autoAlpha: 0, ease: Power0.easeNone, }, 0 );

	// 		new $.ScrollMagic.Scene({
	// 			triggerElement: tr,
	// 			triggerHook: 0,
	// 			duration: '60%',
	// 		})
	// 		.setTween( tl )
	// 		.addTo( this.contrl );
	// 	}, // end headAnim

	// 	bioMiddle : function( $scroll )
	// 	{
	// 		var icon  = $( '#read-more-icon' ),
	// 			bio   = $( '#biography-middle' );

	// 		if ( $scroll && ! bio.hasClass( 'opened' ) )
	// 			return;

	// 		if ( $scroll && bio.hasClass( 'opened' ) )
	// 		{
	// 			icon.removeClass( 'open' );
	// 			return bio.removeClass( 'opened' );
	// 		}

	// 		var h,
	// 			slide = function( $way )
	// 			{
	// 				return TweenMax.to( bio, 1.5, { className : $way + '=opened', ease: Power2.easeInOut, } );
	// 			},

	// 			toggl = function()
	// 			{
	// 				if ( bio.hasClass( 'opened' ) )
	// 				{
	// 					icon.removeClass( 'open' );
	// 					slide( '-' );
	// 				}
	// 				else
	// 				{
	// 					icon.addClass( 'open' );
	// 					slide( '+' );
	// 				}
	// 			},

	// 			tl = new TimelineMax();

	// 			tl
	// 				.to( w, ( $( w ).height() / 1300 ), { scrollTo:{ y: bio, offsetY: ( $( w ).width() > 980 ? 60 : 1 ) }})
	// 				.call( toggl );
	// 	}, // end bioMiddle

	// 	bioClose : function()
	// 	{
	// 		var tr = $( '#biography-close' ),
	// 			tl = new TimelineMax();

	// 		tl.call( this.bioMiddle, [ true ] );

	// 		new $.ScrollMagic.Scene({
	// 			triggerElement: tr,
	// 			triggerHook: 0,
	// 		})
	// 		.setTween( tl )
	// 		.addTo( this.contrl );
	// 	}, // end bioClose

	// 	skills : function()
	// 	{
	// 		var tl = new TimelineMax(),
	// 			tr = $( '#skills' ),
	// 			sk = tr.find( 'div.cloud' ).children( 'span' );

	// 		tl.staggerFrom( sk, 1, { autoAlpha: 0, top : '50%', left : '50%', }, 0.25 );

	// 		new $.ScrollMagic.Scene({
	// 			triggerElement: tr,
	// 			triggerHook: .8,
	// 			duration: '70%',
	// 		})
	// 		.setTween( tl )
	// 		.addTo( this.contrl );
	// 	},

	// 	fadeIn : function()
	// 	{
	// 		var $this = this,
	// 			fades = $( 'div.fadeIn' );

	// 		fades.each( function()
	// 		{
	// 			var tl    = new TimelineMax(),
	// 				pos   = $(this).hasClass( 'left' ) ? '-' : '',
	// 				mid   = $(this).hasClass( 'in' ) ? '0%' : '5%';

	// 			tl.from( $(this), 1, { x: pos + mid, opacity: 0, ease: Power2.easeInOut, } );

	// 			new $.ScrollMagic.Scene({
	// 				triggerElement: $(this),
	// 				triggerHook: .9,
	// 				duration: '80%',
	// 			})
	// 			.setTween( tl )
	// 			.addTo( $this.contrl );
	// 		});
	// 	}, // end fadeIn

	// 	textAnim : function( $sel, $trigg, $hook, $dur, $way )
	// 	{
	// 		var $i = 0,
	// 			way   = $way || false,
	// 			split = new SplitText( $sel, { type : 'chars, words' } ),
	// 			tl    = new TimelineMax(),
	// 			num   = split.chars.length;

	// 		for ( $i; $i < num; $i++ )
	// 		{
	// 			if ( way )
	// 				tl.to( split.chars[$i], 1, { opacity: 0 }, Math.random() * 2) ;

	// 			else
	// 				tl.from( split.chars[$i], 1, { opacity: 0 }, Math.random() * 2) ;
	// 		}

	// 		new $.ScrollMagic.Scene({
	// 			triggerElement: $trigg,
	// 			triggerHook: $hook,
	// 			duration: $dur,
	// 		})
	// 		.setTween( tl )
	// 		.addTo( this.contrl );
	// 	}, // end textAnim

	// 	events : function()
	// 	{
	// 		var $this = this;

	// 		$( '#read-more-icon' ).on( 'click', function()
	// 		{
	// 			$this.bioMiddle( false );
	// 		});
	// 	}, // end events
	// }); // end PageAnimations

	// new PageAnimations({});

});}( jQuery, window ));
